import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 16 16",
  role: "presentation",
  "data-testid": "alm-icon-no-color-ac"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-opacity": ".5",
      d: "M8 3.298A4.682 4.682 0 0 0 3.298 8c0 1.672.836 3.134 2.194 3.97-.313-.522-.626-1.15-.626-1.88 0-1.777 1.358-3.135 3.134-3.135 1.776 0 3.134 1.358 3.134 3.134 0 .732-.209 1.359-.627 1.881 1.359-.836 2.194-2.298 2.194-3.97A4.682 4.682 0 0 0 8 3.298"
    }, null, -1),
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-opacity": ".5",
      d: "M8 7.582c-1.358 0-2.507 1.15-2.507 2.508 0 .94.522 1.671 1.253 2.194-.104-.105-.104-.314-.104-.523 0-.731.627-1.358 1.358-1.358.731 0 1.358.627 1.358 1.358 0 .21 0 .314-.104.523.836-.523 1.254-1.254 1.254-2.194 0-1.359-1.045-2.508-2.508-2.508"
    }, null, -1),
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-opacity": ".5",
      d: "M8 1a7 7 0 1 0 0 14A7 7 0 0 0 8 1m0 12.224c-2.925 0-5.224-2.299-5.224-5.224 0-2.925 2.299-5.224 5.224-5.224 2.925 0 5.224 2.299 5.224 5.224 0 2.925-2.299 5.224-5.224 5.224"
    }, null, -1)
  ])))
}
export default { render: render }