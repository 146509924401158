import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 15 14",
  role: "presentation",
  "data-testid": "alm-icon-no-color-jira"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-opacity": ".5",
      d: "M7.502 1c-1.215 1.197-1.222 3.093.069 4.293l1.763 1.706-1.652 1.599c.465.453.783 1.028.913 1.654a3.15 3.15 0 0 1-.183 1.866l4.944-4.783a.475.475 0 0 0 .144-.336.462.462 0 0 0-.144-.336zm-.903.87L1.644 6.663A.474.474 0 0 0 1.5 7a.462.462 0 0 0 .144.335C3.598 9.22 5.546 11.122 7.5 13c1.186-1.149 1.238-3.048-.041-4.267L5.665 7l1.654-1.6c-.991-.978-1.221-2.363-.72-3.529Z"
    }, null, -1)
  ])))
}
export default { render: render }