import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "#F92344",
  viewBox: "1 1 14 14",
  role: "presentation"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<circle cx=\"8\" cy=\"8\" r=\"6.7\"></circle><circle cx=\"8\" cy=\"8\" r=\"5\" fill=\"#fff\"></circle><circle cx=\"8\" cy=\"8\" r=\"4.5\"></circle><circle cx=\"8\" cy=\"10\" r=\"3\" fill=\"#fff\"></circle><circle cx=\"8\" cy=\"10\" r=\"2.4\"></circle><circle cx=\"8\" cy=\"11.6\" r=\"1.3\" fill=\"#fff\"></circle>", 6)
  ])))
}
export default { render: render }