import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  viewBox: "0 0 16 16",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  role: "presentation"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<path d=\"M14.8307 7.60813L8.60272 1.58375L7.99925 1L3.31101 5.535L1.16719 7.60813C1.06011 7.71224 1 7.85313 1 8C1 8.14687 1.06011 8.28776 1.16719 8.39187L5.45031 12.535L7.99925 15L12.6868 10.465L12.7598 10.395L14.8307 8.395C14.8843 8.34347 14.9269 8.28218 14.956 8.21465C14.985 8.14713 15 8.07471 15 8.00156C15 7.92842 14.985 7.856 14.956 7.78847C14.9269 7.72095 14.8843 7.65966 14.8307 7.60813ZM7.99925 10.07L5.8593 8L7.99925 5.93L10.1385 8L7.99925 10.07Z\" fill=\"#2684FF\"></path><path d=\"M8.1172 5.85471C7.43606 5.21159 7.05208 4.34003 7.04914 3.43041C7.0462 2.5208 7.32029 1.64704 7.99726 1L3.18683 5.65141L5.80976 8L8.1172 5.85471Z\" fill=\"url(#paint0_linear_2113_2025)\"></path><path d=\"M10.1841 8.00049L7.91461 10.0455C8.59833 10.6913 8.98243 11.5672 8.98243 12.4805C8.98243 13.3938 8.68037 14.3546 7.99664 15.0005L12.8091 10.363L10.1841 8.00049Z\" fill=\"url(#paint1_linear_2113_2025)\"></path><defs><linearGradient id=\"paint0_linear_2113_2025\" x1=\"7.73149\" y1=\"3.78524\" x2=\"4.87987\" y2=\"6.80409\" gradientUnits=\"userSpaceOnUse\"><stop offset=\"0.18\" stop-color=\"#0052CC\"></stop><stop offset=\"1\" stop-color=\"#2684FF\"></stop></linearGradient><linearGradient id=\"paint1_linear_2113_2025\" x1=\"8.32789\" y1=\"12.0902\" x2=\"11.174\" y2=\"9.07724\" gradientUnits=\"userSpaceOnUse\"><stop offset=\"0.18\" stop-color=\"#0052CC\"></stop><stop offset=\"1\" stop-color=\"#2684FF\"></stop></linearGradient></defs>", 4)
  ])))
}
export default { render: render }