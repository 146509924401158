import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "currentColor",
  viewBox: "0 0 32 32",
  "data-testid": "alm-icon-no-color-ado",
  role: "presentation"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "m0 11.865 2.995-3.953 11.208-4.557V.063l9.828 7.188-20.078 3.896v10.969L0 20.975zm32-5.933v19.536l-7.672 6.531-12.401-4.073v4.073l-7.974-9.885 20.078 2.396V7.25z" }, null, -1)
  ])))
}
export default { render: render }